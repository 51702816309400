header{
   height: 100vh;
   padding-top: 7rem;
   overflow: hidden;
  
}

.header_container {
   text-align: center;
   height: 100%;
   position: relative;
}

/* =====CTA====== */

.cta {
   margin-top: 2.5rem;
   display: flex;
   gap: 1.2rem;
   justify-content: center;
   z-index: -1000;
   
}

/* =====HEADER SOCIALS====== */

.header__socials {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 0.8rem;
   position: absolute;
   left: 0;
   bottom: 3rem;

}

.header__socials::after {
   content: "";
   width: 1px;
   height: 2rem;
   background: var(--color-primary);
}

/* =====COLLINS====== */
.me {
   background: linear-gradient(var(--color-primary), transparent);
   width: 22rem;
   height: 30rem;
   position: absolute;
   left: calc(50% - 11rem);
   margin-top: 4rem;
   border-radius: 12rem 12rem 0 0;
   overflow: hidden;
   padding: 1.5rem 1.5rem  1.5rem;
}



/* =====COLLINS====== */
.scroll__down {
   position: absolute;
   right: -2.3rem;
   bottom: 5rem;
   transform: rotate(90deg);
   font-weight: 300;
   font-size: 0.9rem;
}


/* ==========MEDIA QUERIES (MEDIUM SCREEN)============ */


@media screen and (max-width:1024px) {
   header {
      height: 68vh;
   }
 }
 
 /* ==========MEDIA QUERIES (SMALL SCREEN)============ */
 
 
 @media screen and (max-width:480px) {
   header {
      height: 100vh;
   }

   .me {
      display: block
      }


   .header__socials, .scroll__down {
      display: none;
   }
    .header_container {
      margin: auto 15px 0 15px;
      height: 70%;
    }

    .header_container h1 {
      font-size: 40px;
    }

    .btn {
   padding: 0.4rem 0.8rem;
}

header{
   padding-top: 4rem;

}
  }